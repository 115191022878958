import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import { Wrapper, Container, below } from "../styles"
import { SplitBanner } from "../components/banners/SplitBanner"
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import SignUpBanner from "../components/banners/SignUpBanner"

const Divider = styled.hr`
  padding-top: var(--basePadding);
  ${below.medium`
    margin-bottom: 6rem;
  `}
`

const TiledBanners = styled.div`
  img {
    max-height: 480px
  }
`

const Page = ({ data }: { data: any }) => {
  return (
    <>
      <SEO
        title="Pro Rewards Program"
        description="Rachio Pro Rewards is a new program giving you the chance to earn Free Pro Series Rachio Controllers in exchange for activating them for your customers."
        pathname="/pro-rewards/"
      />
      <Wrapper>
        <h1 className="center-text blue-text">
          Join Rachio Pro Rewards Program
        </h1>
        <p className="largest center-text">
          Rachio Pro Rewards is a new program giving you the chance to earn Free Pro Series
          Rachio Controllers in exchange for activating them for your customers. For every
          10 you activate, you’ll earn a FREE 8-Zone Pro Series Controller.
        </p>
        <Divider />
      </Wrapper>
      <TiledBanners>
        <SplitBanner
          flipMobile={true}
          left={
            <GatsbyImage
              image={getImage(data.terminalsImg.childImageSharp.gatsbyImageData) as IGatsbyImageData}
              alt="Pro Controller Terminals"
            />
          }
          right={
            <div className="features-content">
              <h2>How it works</h2>
              <ul>
                <li>Activate Pro Series controllers using your Rachio app email address</li>
                <li>Share Access or Transfer Ownership to homeowner after completing the controller activation</li>
                <li>Earn 10 points for every eligible controller you activate </li>
                <li>Get one free 8-zone Pro Series controller at pro distribution for every 100 points earned</li>
              </ul>
            </div>
          }
        />
        
        <SplitBanner
          className="gray-bg"
          left={
            <div className="features-content">
              <h2>Requirements</h2>
              <ul>
                <li>All Pro Controllers are eligible for this program.</li>
                <li>Must use the email associated with your Rachio app when signing up/re-signing up for the Rachio Pro <strong><Link to="/signup/" className="blue-text">application</Link></strong>.</li>
                <li>Must activate controllers using your Rachio app. Your email is used to track and receive points for activations.</li>
                <li>Shared Access/Transferred Ownership to your account after the controller is activated will NOT count for points.</li>
                <li>Once the controller is activated, the controller cannot be reactivated to receive points.</li>
                <li>Rewards points will only be awarded to the email address used to activate the controller.</li>
              </ul>
            </div>
          }
          right={
            <StaticImage
              src="https://cdn.shopify.com/s/files/1/1014/9473/files/pro-screen.jpg?v=1651593260"
              alt="Sprinkler Head"
            />
          }
        />
        
      </TiledBanners>
      <SignUpBanner />
    </>
  )
}

export const query = graphql`
  query {
    terminalsImg: file(relativePath: { eq: "pages/home/r3ps-2.png"}){
      ...fullFluidImage
    }
  }
`

export default Page